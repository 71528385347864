@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap');

*,
*::before,
*::after {
  position: relative;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.5;
  font-family: 'Lato', sans-serif;
  --color-primary: #137a4e;
}

.App {
  font-family: sans-serif;
}

.sgv-nav-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  color: white;
  background-color: var(--color-primary);
  text-align: left;
  columns: 3;
  padding: 0.5rem;
  font-weight: bold;
  > div {
    text-align: center;
  }
}
.sgv-nav-header a {
  text-decoration: none;
  color: white;
}

.sgv-contact-link {
  display: inline-flex;
  align-items: center;
  gap: 0.1rem;
}

.contact-icon {
  width: 50px;
  height: 40px;
}

.contact-phone {
  font-size: x-large;
}

.social-media {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

#customer-review-hc {
  width: auto;
  height: 35px;
}

#customer-review-yelp {
  width: auto;
  height: 30px;
}

#customer-review-ig {
  width: auto;
  height: 30px;
}

#customer-review-fb {
  width: auto;
  height: 30px;
}

.hours {
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem;
  margin: 0;
  font-size: large;
}

.sgv-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
}

.sgv-hero::after {
  content: '';
  // background: url(https://images.unsplash.com/photo-1506267594256-7667b0040d31?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80);
  background: var(--hero); 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.8;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.sgv-hero-inner {
  text-align: center;
  margin: 0;
  background: #0007;
  color: #dcdcdc;
  padding: 1rem;
  width: 70%;
  height: 70%;
}

.sgv-hero-heading {
  color: white;
  text-shadow: 0 0.25rem 0.25rem #0007;
}

h1 > small {
  font-size: 50%;
  text-transform: uppercase;
  letter-spacing: 0.25ch;
}

.order-button {
  appearance: none;
  border: none;
  background: var(--color-primary);
  padding: 1rem;
  color: white;
  font-size: 125%;
  font-weight: bold;
  border-radius: 0.25rem;
}

.order-button a {
  text-decoration: none;
  color: white;
}

#logo {
  display: block;
  margin: auto;
  padding: 1rem;
  width: 30%;
  min-width: 300px;
  height: auto;
}

.item {
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: minmax(0, min-content) 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'photo item-en'
    'photo item-vi'
    'photo item-description';
  grid-column-gap: 1rem;
  box-shadow: 0 0.25rem 0.5rem #0002;
  padding: 0.5rem;

  > .item-photo {
    width: 100px;
    grid-area: photo;
  }

  border-radius: 0.25rem;
  > .item-en {
    grid-area: item-en;
  }
  > .item-vi {
    grid-area: item-vi;
  }
  > .item-description {
    grid-area: item-description;
  }
}

.item-photo {
  cursor: pointer;
  > img {
    width: 100%;
    border-radius: 0.25rem;
  }

  &[data-view='small'] {
    &:hover {
      opacity: 0.7;
    }
  }

  &[data-view='big'] {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fffc;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    animation: fade-in 0.3s ease;

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    > img {
      width: 90vw;
      max-width: 800px;
      height: auto;
    }
  }
}

.item-vi {
  font-style: italic;
}

.item-description {
  font-size: 80%;
}

.sgv-menu {
  padding-top: 5rem;
  padding-bottom: 5rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: var(--bkg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 0.7;
  }
}

.sgv-menu-items {
  padding-top: 0;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  columns: 2;
  background: #fefefe;
  width: 70%;
  margin: auto;
}

.item-en {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left-column,
.right-column {
  padding: 2rem;
}

.left-column {
  border-right: 1px solid #dcdcdc;
}

.item.new-item {
  border: 2px solid var(--color-primary);
  box-shadow: none;
}

.sgv-nav-footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: white;
  background-color: var(--color-primary);
  text-align: left;
  padding: 0.5rem;
  font-weight: bold;
  > div {
    text-align: center;
  }
}

.sgv-nav-footer a {
  text-decoration: none;
  color: white;
}

// Mobile styles
@media (max-width: 900px) {
  .sgv-nav-header {
    grid-template-columns: repeat(1, 1fr);
  }
  .sgv-hero {
    padding: 0;
  }
  .sgv-hero-inner {
    width: 100%;
  }
  .sgv-menu {
    padding: 0;
  }
  .sgv-menu-items {
    padding: 0;
    width: 95%;
    columns: 1;
    display: block;
  }
  .left-column,
  .right-column {
    padding: 0 2rem;
  }

  .hide-on-mobile {
    display: none;
  }

  .sgv-nav-footer {
    grid-template-columns: repeat(1, 1fr);
  }
}
